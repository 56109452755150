import React from "react";
import { useOverrides } from "@quarkly/components";
import { Image, LinkBox, Box, Link, Text, Input, Button, Section } from "@quarkly/widgets";
import QuarklycommunityKitNetlifyForm from "./QuarklycommunityKitNetlifyForm";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"quarkly-title": "Footer-4"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "row",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 30px 0px",
			"md-flex-direction": "column",
			"md-display": "flex"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "30%",
			"display": "flex",
			"lg-width": "30%",
			"sm-width": "20%",
			"md-width": "10%"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"justify-content": "center",
			"border-radius": "100%",
			"width": "36px",
			"height": "36px",
			"align-items": "center",
			"display": "flex",
			"href": "/index"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://quixetstar.com/img/6750831.png",
			"display": "block",
			"width": "36px",
			"height": "36px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 34px",
			"lg-align-items": "start",
			"md-grid-gap": "24px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "36px 24px",
			"sm-width": "100%"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "--darkL1",
			"hover-color": "--primary",
			"children": "Home"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/our-services",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "--darkL1",
			"hover-color": "--primary",
			"children": "Our Services"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--primary",
			"href": "/contacts",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "--darkL1",
			"display": "flex",
			"children": "Contacts"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "50px 0px 50px 0px",
			"display": "grid",
			"justify-content": "space-between",
			"grid-template-columns": "repeat(2, 1fr)",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"md-grid-template-columns": "1fr",
			"md-padding": "30px 0px 30px 0px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"padding": "0px 50px 0px 0px",
			"md-padding": "0px 0 0px 0px",
			"md-margin": "0px 0px 25px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"sm-text-align": "center",
			"children": "Embark on Your Fitness Odyssey – Join Flex Frontier Today!"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#5a5d64",
			"sm-text-align": "center",
			"children": "Step into a realm where fitness goals are met with enthusiasm and support. Flex Frontier isn't just a gym, it's the beginning of a new chapter in your fitness story. Empower your journey with us today."
		}
	},
	"quarklycommunityKitNetlifyForm": {
		"kind": "QuarklycommunityKitNetlifyForm",
		"props": {
			"display": "flex",
			"justify-content": "flex-end",
			"md-justify-content": "flex-start",
			"sm-justify-content": "center"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-flex-wrap": "no-wrap",
			"sm-flex-wrap": "wrap"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"margin": "0px 16px 0px 0px",
			"padding": "10px 16px 10px 16px",
			"placeholder": "Enter your e-mail",
			"width": "100%",
			"font": "normal 300 18px/1.5 --fontFamily-sans",
			"max-width": "386px",
			"border-radius": "8px",
			"name": "Mobile ",
			"type": "tel",
			"required": true,
			"lg-margin": "0px 10px 0px 0px",
			"sm-margin": "0px 0 0 0px",
			"sm-text-align": "center"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"padding": "10px 24px 10px 24px",
			"font": "normal 400 18px/1.5 --fontFamily-sans",
			"sm-margin": "10px 0px 0px 0px",
			"sm-width": "100%",
			"border-style": "solid",
			"focus-box-shadow": "none",
			"color": "--light",
			"background": "--color-primary",
			"border-radius": "8px",
			"border-width": "2px",
			"border-color": "--color-primary",
			"white-space": "nowrap"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#5a5d64",
			"md-margin": "0px 0px 25px 0px",
			"sm-text-align": "center",
			"children": "quixetstar.com © 2024 All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
				</LinkBox>
			</Box>
			<Box {...override("box2")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box6")}>
			<Text {...override("text2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;